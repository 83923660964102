module.exports = {
  // App Info
  appstore_link:
    "https://itunes.apple.com/us/app/my-journey-smart-journal/id1462759197?ls=1&mt=8", // Enter App Store URL.
  playstore_link: "https://play.google.com/store/apps/details?id=com.myjourney", // Enter Google Play Store URL.
  google_analytics_ID: "UA-139880843-1", // Enter Google Analytics ID or ""
  presskit_download_link: null, // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://myjourney.today", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "My Journey",
  app_price: "Free",
  app_description: "Smart Journal that makes you smile",
  app_keywords: ["journal", "dairy", "anxiety", "stress", "mood", "tracker"],

  // Personal Info
  author_1: "Iva Koilova",
  author_1_link: "https://medium.com/@curiousbutterfly",
  author_2: "Venelin Valkov",
  author_2_link: "https://curiousily.com",
  email_address: "artisanpath@gmail.com",
  facebook_username: null,
  instagram_username: "myjourneyapp",
  twitter_username: "myjourneyapp",
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "Daily Mood Tracker",
      description:
        "Track your mood, anxiety, depression and write down how you feel. You will feel better, instantly!",
      fontawesome_icon_name: "smile-beam",
    },
    {
      title: "The easiest way to journal",
      description:
        "Track everything you do without even typing! Add entries throughout your day and turn it into an amazing memory!",
      fontawesome_icon_name: "book-open",
    },
    {
      title: "Completely private",
      description:
        "My Journey DOES NOT show ads, share your data with anyone (including the developers), save analytics data. Your data stays safe, only on your device!",
      fontawesome_icon_name: "lock",
    },
    {
      title: "Find out what affects your mood",
      description:
        "Analyze your moods and tasks you do with detailed statistics. Find which activities consistently makes you feel moody or happy.",
      fontawesome_icon_name: "chart-line",
    },
    {
      title: "Be inspired",
      description:
        "Start your day with inspirational quote and choose your focus for the day",
      fontawesome_icon_name: "sun",
    },
    {
      title: "Keep your memories safe",
      description:
        "Revisit past memories and relive happy moments! Keep your data safely stored on your device, completely private!",
      fontawesome_icon_name: "tree",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.25)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(66, 66, 66, 0.65)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "#303030",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#2196F3",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "rgba(255, 255, 255, 0.87)",
  feature_text_color: "rgba(255, 255, 255, 0.54)",
  feature_icons_foreground_color: "#673AB7",
  feature_icons_background_color: "#FFFFFF",
  social_icons_foreground_color: "#673AB7",
  social_icons_background_color: "#FFFFFF",
  footer_text_color: "rgba(255, 255, 255, 0.54)",
}
